import {blue, indigo, grey} from "@mui/material/colors";

export default function getDesignTokens(mode) {
    return ({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    primary: {
                        light: blue[50],
                        main: blue[100],
                        dark: blue[500],
                    },
                    secondary: {
                        light: indigo["A100"],
                        main: indigo["A200"],
                        dark: indigo["A700"],
                    },
                    text: {
                        primary: grey[700],
                        secondary: blue["900"],
                    },
                    background: {
                        default: grey[200],
                        paper: grey[50]
                    }
                }
                : {
                    // palette values for dark mode
                    primary: {
                        light: blue[300],
                        main: blue[500],
                        dark: blue[700],
                    },
                    secondary: {
                        light: blue["A50"],
                        main: blue["A100"],
                        dark: blue["A400"],
                    },
                    text: {
                        primary: grey[400],
                        secondary: blue[300],
                    },
                    background: {
                        default: '#1a2035',
                        paper: '#202940',
                    },
                })
        },
        typography: {
            mode,
            ...(mode === 'light') ? {
                /*fontFamily: 'Roboto',*/
            } : {
                /*fontFamily: 'sans',*/
            },
            fontFamily: [
                'Roboto',
            ].join(',')
        },
        components: {
            mode,
            ...(mode === 'light'
                    ? {
                        // Name of the component
                        MuiButton: {
                            styleOverrides: {
                                // Name of the slot
                                root: {
                                    /*fontSize: '1rem',*/
                                    color: indigo["A200"],
                                },
                            },
                        },
                        MuiCard: {
                            styleOverrides: {
                                root: {
                                    backgroundColor: grey[100],
                                }
                            }
                        }
                    } : {
                        MuiButton: {
                            styleOverrides: {
                                root: {
                                    color: blue["A100"],
                                },
                            },
                        },
                        MuiCard: {
                            styleOverrides: {
                                root: {
                                    backgroundColor: '#202940',
                                }
                            }
                        }

                    }
            ),
        },

    });
};
