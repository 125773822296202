import React from "react"
import coverImg from "../assets/stelvio.jpg";

const styles = {
    coverImg: {
        'minHeight': '400px',
        'background': 'url(' + coverImg + ') no-repeat center',
        'backgroundSize': 'cover'
    }
}

export default class PageCoverImage extends React.Component {

    render() {
        return (
            <div style={styles.coverImg} />
        );
    }

}