import React from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import PageCoverImage from "./PageCoverImage";

export default class MainPageHeader extends React.Component {

    render() {
        return (
            <div >
                <ResponsiveAppBar />
                <PageCoverImage />
            </div>
        );
    }
}