import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard() {
    return (
        <Card>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="240"
                    image={ require("../assets/cards/contemplative-reptile.jpg") }
                    alt="green iguana"

                    // todo: add video component
                    /*component="iframe"
                    src={ "https://player.vimeo.com/video/263313304" }
                    allow="autoPlay"*/

                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" color="text.secondary">
                        Lizard
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        Lizards are a widespread group of squamate reptiles, with over 6,000
                        species, ranging across all continents except Antarctica
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}