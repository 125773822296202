
export function createCookie(cookieName, cookieValue) {
    document.cookie = cookieName + " = " + cookieValue;
};

export function getCookie(cookieName, defaultValue) {

    const retVal = document.cookie.split(';')
        .find((row) => row.startsWith(cookieName))
        ?.split('=')[1];

    if (retVal !== undefined)
        return retVal;

    return defaultValue;
}