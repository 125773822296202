import MainPageHeader from "./components/MainPageHeader";
import ActionAreaCard from "./components/ActionAreaCard";
import {Grid} from "@mui/material";
import React from "react";
import { ThemeProvider } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import createTheme from "@mui/material/styles/createTheme";
import getDesignTokens from "./themes/theme";
import {createCookie, getCookie} from "./utils/CookiesUtils";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const UserContext = React.createContext({ toggleColorMode: () => {} });

function App() {

    const lastThemeMode = getCookie('last-theme-mode', 'light');
    const [mode, setMode] = React.useState(lastThemeMode);
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                const newMode = (mode === "light") ? "dark" : "light";
                setMode(newMode);
                createCookie('last-theme-mode', newMode);
            },
        }),
        [mode],
    );

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (

        <UserContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme/>

                <div className="App">
                    <MainPageHeader/>

                    <Grid className="main-grid" container
                          spacing={{xs: 1, sm: 2, md: 3}}
                          columns={{xs: 1, sm: 2, md: 3, lg: 4}}
                          sx={{px: '1rem', py: '1rem'}}
                    >

                        {Array.from(Array(12)).map((_, index) => (
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={index}>
                                <ActionAreaCard />
                            </Grid>
                        ))}

                    </Grid>
                </div>

            </ThemeProvider>
        </UserContext.Provider>
    );
}

export default App;
