import IconButton from "@mui/material/IconButton";
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import React from 'react'
import {UserContext} from "../App";
import {useTheme} from "@mui/material/styles";

export default function SwitchThemeButton(props) {

    const theme = useTheme();
    const colorMode = React.useContext(UserContext);

    return (
        <UserContext.Consumer>
            {
                value =>
                <IconButton onClick={colorMode.toggleColorMode} color = {props.color}>
                    {theme.palette.mode  === "dark" ? <LightModeTwoToneIcon/> : <DarkModeTwoToneIcon/>}
                </IconButton>
            }
        </UserContext.Consumer>
    );

}

