import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import {alpha, styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import SwitchThemeButton from "./SwitchThemeButton";

const pages = ['Cestopisy', 'Videá'/*, 'Blog'*/];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.common.white, 0.35) : alpha(theme.palette.common.white, 0.05),
    '&:hover': {
        backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.common.white, 0.45) : alpha(theme.palette.common.white, 0.15),
    },
    color: theme.palette.secondary.main,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div >
        <AppBar position="fixed" className="responsive-app-bar" sx={{ opacity: 0.8}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <SportsMotorsportsIcon color="secondary" sx={{
                        display: { xs: 'none', md: 'flex' },
                        mr: 1,
                        transform: "rotateY(180deg)"
                    }} />
                    <Typography
                        variant="h7"
                        noWrap
                        component="p"
                        color="secondary"
                        sx={{
                            mr: 4,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            textDecoration: 'none',
                        }}
                    >
                        René Gladiš
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="secondary"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu} >
                                    <Typography textAlign="center" >{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <SportsMotorsportsIcon color="secondary" sx={{
                        display: { xs: 'flex', md: 'none' },
                        mr: 1,
                        transform: "rotateY(180deg)"
                    }} />
                    <Typography
                        variant="h7"
                        noWrap
                        component="p"
                        color="secondary"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'Roboto',
                            fontWeight: 900,
                            letterSpacing: '.2rem',
                            textDecoration: 'none',
                        }}
                    >
                        René Gladiš
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Search sx={{
                        flexGrow: 0,
                        mr: 2,
                        display: { xs: 'none', sm: 'flex' }
                    }}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Hľadaj článok, video, …"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>

                    <SwitchThemeButton color="secondary"/>

                </Toolbar>
            </Container>
        </AppBar>
        </div>
    );
}
export default ResponsiveAppBar;